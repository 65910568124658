<template>
  <div>
    <PCNavbar ref="navbar" selectIndex="3"/>
    <MobileNavBar selectIndex="3" />
    <SubNavBar />
    <router-view></router-view>
  </div>
</template>

<script>
import PCNavbar from '@/components/navbar/index'
import MobileNavBar from '@/components/navbar/MobileNavBar.vue'
import SubNavBar from '@/components/navbar/SubNavBar.vue'
export default {
  components: {
    PCNavbar,
    MobileNavBar,
    SubNavBar
  },
}
</script>

<style>
</style>